import React from 'react';
import Navbar from 'components/layout/Navbar';
import Head from 'components/Head';
import FullScreenNavigation from 'components/layout/FullScreenNavigation/FullScreenNavigation';
import cn from 'classnames';
import s from './Page.module.scss';

export default function Page({ children, bgColor, main, primaryNavbar, ...rest }) {
  return (
    <div className={s.container}>
      <Head {...rest} />
      <Navbar primaryNavbar={primaryNavbar} />
      <FullScreenNavigation />
      <div className={cn(s.content, main ? s.mainPage : null)}>{children}</div>
    </div>
  );
}
