/* eslint-disable react/button-has-type */
import React from 'react';
import cn from 'classnames';
import s from './Button.module.scss';

export const variantToClassNameMap = {
  primary: s.primary,
  outlined: s.outlined,
  secondary: s.secondary,
  danger: s.danger,
};

const Button = ({ children, type = 'button', className, onClick, variant = 'primary' }) => {
  return (
    <button type={type} className={cn(s.btn, variantToClassNameMap[variant], className)} onClick={onClick}>
      {children}
    </button>
  );
};

export default Button;
