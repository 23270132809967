import React, { useState } from 'react';
import { Link } from 'gatsby';
import Dropdown from 'rc-dropdown';
import Menu, { Item as MenuItem } from 'rc-menu';
import Button from 'components/common/Button';
import 'rc-dropdown/assets/index.css';
import { useI18n } from 'i18n';
import routePaths from 'constants/route-paths';
import s from './Navbar.module.scss';

const DropdownMenu = ({ setVisible }) => {
  const { t, locale } = useI18n();
  return (
    <Menu className={s.menu}>
      <MenuItem key="1" className={s.menuItemBtn} onClick={() => setVisible(false)}>
        <Link to={routePaths[locale].building_cost_calculator}>
          <Button>{t('navigation.services.building_cost_calculator')}</Button>
        </Link>
      </MenuItem>
      <MenuItem key="2" className={s.menuItemBtn} onClick={() => setVisible(false)}>
        <Link to={routePaths[locale].home_loan_calculator}>
          <Button>{t('navigation.services.home_loan_calculator')}</Button>
        </Link>
      </MenuItem>
      <MenuItem key="3" className={s.menuItemBtn} onClick={() => setVisible(false)}>
        <Link to={routePaths[locale].financing_calculator}>
          <Button>{t('navigation.services.financing_calculator')}</Button>
        </Link>
      </MenuItem>
      <MenuItem key="4" className={s.menuItemBtn} onClick={() => setVisible(false)}>
        <Link to={routePaths[locale].renovation_house}>
          <Button>{t('navigation.services.renovation_house')}</Button>
        </Link>
      </MenuItem>
      <MenuItem key="5" className={s.menuItemBtn} onClick={() => setVisible(false)}>
        <Link to={routePaths[locale].whitelabel}>
          <Button>{t('navigation.services.white_label')}</Button>
        </Link>
      </MenuItem>
    </Menu>
  );
};

const DropdownServices = () => {
  const [visible, setVisible] = useState(false);
  const { t } = useI18n();
  return (
    <Dropdown
      trigger={['click']}
      overlay={<DropdownMenu alignPoint setVisible={setVisible} />}
      animation="slide-up"
      closeOnSelect={false}
      onVisibleChange={setVisible}
      visible={visible}
      openClassName={s.activeServiceItem}
    >
      <div className={s.triggeredContent}>{t('navigation.services.label')}</div>
    </Dropdown>
  );
};

export default DropdownServices;
