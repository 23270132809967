import useBreakpoint from 'use-breakpoint';

const hidden = ({ screenClass, xs, sm, md, lg, xl, xxl }) => {
  if (screenClass === 'xxl') return xxl;
  if (screenClass === 'xl') return xl;
  if (screenClass === 'lg') return lg;
  if (screenClass === 'md') return md;
  if (screenClass === 'sm') return sm;
  return xs;
};

export const screenSizeConfig = { xs: 0, sm: 576, md: 768, lg: 992, xl: 1200, xxl: 1600 };

export const Hidden = ({ xs = false, sm = false, md = false, lg = false, xl = false, xxl = false, children }) => {
  const { breakpoint } = useBreakpoint(screenSizeConfig, 'lg');
  return hidden({ screenClass: breakpoint, xs, sm, md, lg, xl, xxl }) ? null : children;
};

export const Visible = ({ xs = false, sm = false, md = false, lg = false, xl = false, xxl = false, children }) => {
  const { breakpoint } = useBreakpoint(screenSizeConfig, 'lg');
  return !hidden({ screenClass: breakpoint, xs, sm, md, lg, xl, xxl }) ? null : children;
};
