import React from 'react';
import cn from 'classnames';
import { Link } from 'gatsby';
import { ReactComponent as Logo } from 'assets/icons/logo.svg';
import { appHomeUrl } from 'config/settings';
import { Spin as Hamburger } from 'hamburger-react';
import Button from 'components/common/Button';
import { useI18n } from 'i18n';
import { useAppContext } from 'contexts/AppContext';
import routePaths from 'constants/route-paths';
import { trackCTAClick } from 'utils/analytics';
import LanguageSwitcher from 'components/LanguageSwitcher/LanguageSwitcher';
import DropdownServices from './Dropdown';
import s from './Navbar.module.scss';

const links = [
  {
    key: 'navigation.main',
    component: Link,
    getPath: ({ locale }) => routePaths[locale].home,
  },
  {
    key: 'navigation.about',
    component: Link,
    getPath: ({ locale }) => routePaths[locale].about,
  },
  {
    key: 'navigation.services',
    component: DropdownServices,
    getPath: () => {},
  },
  {
    key: 'navigation.blog',
    component: Link,
    getPath: ({ locale }) => routePaths[locale].blog,
  },
  {
    key: 'navigation.contact',
    component: Link,
    getPath: ({ locale }) => routePaths[locale].contact,
  },
];

export default function Navbar({ primaryNavbar }) {
  const { navOpen, toggleNav } = useAppContext();
  const { t, locale } = useI18n();

  return (
    <nav className={cn(s.navbar, navOpen ? s.navbarOpen : '', primaryNavbar ? s.primaryNavbar : null)}>
      <div className={cn('container', s.navbarInner)}>
        <div className={s.logoLinkContainer}>
          <Link to={routePaths[locale].home}>
            <Logo className={cn(navOpen ? s.secondaryLogo : s.logo, s.desktopLogo)} />
            <Logo
              viewBox={navOpen ? '0 0 131 30' : '0 0 30 30'}
              className={cn(navOpen ? s.secondaryLogo : s.logo, s.mobileLogo)}
            />
          </Link>
        </div>
        <div className={s.navbarContent}>
          <div className={s.navigation}>
            {links.map(({ component: C, getPath, key }) => (
              <C key={key} to={getPath({ locale })} activeClassName="active-link-styles">
                {t(key)}
              </C>
            ))}
          </div>

          <LanguageSwitcher />
          <a className={s.ctaBtn} href={`${appHomeUrl}?lang=${locale}`} target="_blank" rel="noreferrer">
            <Button variant="danger" onClick={() => trackCTAClick('Navbar')}>
              {t('navigation.btn_label')}
            </Button>
          </a>
          <div className={cn(s.hamburger, { [s.hamburgerActive]: navOpen })}>
            <Hamburger toggled={navOpen} toggle={toggleNav} />
          </div>
        </div>
      </div>
    </nav>
  );
}
