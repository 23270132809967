export const appHomeUrl = process.env.GATSBY_APP_HOME_URL;
export const stagingAppHomeUrl = process.env.GATSBY_STAGING_APP_HOME_URL;
export const contactEmail = process.env.GATSBY_CONTACT_EMAIL;
export const cookiebotScriptUrl = process.env.GATSBY_COOKIEBOT_SCRIPT_URL;
export const cookiebotDomainGroupId = process.env.GATSBY_COOKIEBOT_DOMAIN_GROUP_ID;
export const defaultFacebookUrl = process.env.GATSBY_DEFAULT_FACEBOOK_URL;
export const defaultInstagramUrl = process.env.GATSBY_DEFAULT_INSTAGRAM_URL;
export const defaultTikTokUrl = process.env.GATSBY_DEFAULT_TIKTOK_URL;
export const iframeUrl = process.env.GATSBY_IFRAME_URL;
export const iframeAdminUrl = process.env.GATSBY_IFRAME_ADMIN_URL;

export default {
  isDevelopment: process.env.NODE_ENV === 'development',
  isProduction: process.env.NODE_ENV !== 'development',
};
