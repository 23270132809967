const routePaths = {
  en: {
    home: '/',
    about: '/en/about-us',
    blog: '/blog',
    contact: '/contact',
    privacyPolicy: '/en/privacy-policy',
    termsOfUse: '/en/terms-of-use',
    cookies: '/cookie-declaration',
    whitelabel: '/whitelabel',
    building_cost_calculator: '/calculator/en/building-cost-calculator',
    home_loan_calculator: '/calculator/en/home-loan-calculator',
    financing_calculator: '/calculator/en/finance-calculator',
    renovation_house: '/calculator/en/renovation-calculator',
    articles_list: {
      article_1: '/blog/en/cheapest-loan',
      article_2: '/blog/en/planning-house-9-tips',
      article_3: '/blog/en/6-tips-for-loan-interview',
      article_4: '/blog/en/8-tips-happy-living-checklist',
      article_5: '/blog/en/house-building-costs',
      article_6: '/blog/en/your-home-an-investment-in-the-future',
      article_7: '/blog/en/what-does-a-house-cost',
      article_8: '/blog/en/save-money-and-protect-the-environment',
    },
  },
  de: {
    home: '/',
    about: '/ueber-uns',
    blog: '/blog',
    contact: '/kontakt',
    privacyPolicy: '/datenschutz',
    termsOfUse: '/nutzungsbedingungen',
    cookies: '/cookie-declaration',
    whitelabel: '/whitelabel',
    building_cost_calculator: '/rechner/baukostenrechner',
    home_loan_calculator: '/rechner/wohnkredit-rechner',
    financing_calculator: '/rechner/finanzierungsrechner',
    renovation_house: '/rechner/sanierungsrechner',
    articles_list: {
      article_1: '/blog/guenstigster-kredit',
      article_2: '/blog/haus-bauen-9-tipps',
      article_3: '/blog/6-tipps-furs-kreditgesprach',
      article_4: '/blog/8-tipps-zum-wohngluck-checkliste-wohnungskauf',
      article_5: '/blog/hausbau-kosten',
      article_6: '/blog/ihr-eigenheim-eine-investition-in-die-zukunft',
      article_7: '/blog/was-kostet-ein-haus',
      article_8: '/blog/de/dank-altbausanierung-geld-sparen-und-umwelt-schonen',
    },
  },
};

export default routePaths;
