/* eslint-disable no-return-assign */
import React from 'react';
import { useAppContext } from 'contexts/AppContext';
import cn from 'classnames';
import { useI18n } from 'i18n';
import { Link } from 'gatsby';
import { appHomeUrl } from 'config/settings';
import Button from 'components/common/Button';
import { Visible } from 'components/Grid';
import Collapsible from 'react-collapsible';
import routePaths from 'constants/route-paths';
import s from './FullScreenNavigation.module.scss';

const FullScreenNavigation = () => {
  const { navOpen } = useAppContext();
  const { t, locale } = useI18n();

  return (
    <div className={cn(s.overlay, { [s.open]: navOpen })}>
      <div className={s.overlayContent}>
        <ol className={s.menu}>
          <Link to={routePaths[locale].home}>
            <li className={s.menuItem}>{t('navigation.main')}</li>
          </Link>
          <Link to={routePaths[locale].about}>
            <li className={s.menuItem}>{t('navigation.about')}</li>
          </Link>
          <li className={s.menuItem}>
            <ul className={s.servicesList}>
              <Collapsible trigger={t('navigation.services.label')} open>
                <Link to={routePaths[locale].building_cost_calculator}>
                  <li className={s.menuItem}>{t('navigation.services.building_cost_calculator')}</li>
                </Link>
                <Link to={routePaths[locale].home_loan_calculator}>
                  <li className={s.menuItem}>{t('navigation.services.home_loan_calculator')}</li>
                </Link>
                <Link to={routePaths[locale].financing_calculator}>
                  <li className={s.menuItem}>{t('navigation.services.financing_calculator')}</li>
                </Link>
                <Link to={routePaths[locale].renovation_house}>
                  <li className={s.menuItem}>{t('navigation.services.renovation_house')}</li>
                </Link>
                <Link to={routePaths[locale].whitelabel}>
                  <li className={s.menuItem}>{t('navigation.services.white_label')}</li>
                </Link>
              </Collapsible>
            </ul>
          </li>
          <Link to={routePaths[locale].blog}>
            <li className={s.menuItem}>{t('navigation.blog')}</li>
          </Link>
          <Link to={routePaths[locale].contact}>
            <li className={s.menuItem}>{t('navigation.contact')}</li>
          </Link>
        </ol>
        <Visible xs sm>
          <a href={`${appHomeUrl}?lang=${locale}`} target="_blank" rel="noreferrer" className={s.ctaButton}>
            <Button variant="danger">{t('navigation.btn_label')}</Button>
          </a>
        </Visible>
      </div>
    </div>
  );
};

export default FullScreenNavigation;
