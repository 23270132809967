/* eslint-disable react/no-danger */
import React, { useEffect } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import Title from 'components/common/Title';
import Page from 'components/layout/Page';
import facebookIconSrc from 'assets/icons/facebook.svg';
import instagramIconSrc from 'assets/icons/instagram.svg';
import tikTokIconSrc from 'assets/icons/tiktok.svg';
import { useI18n } from 'i18n';
import { isNil } from 'lodash';
import s from './PageLayout.module.scss';
import Footer from '../Footer';

const PageLayout = ({
  children,
  i18nTitle,
  i18nMetaTitle,
  i18nMetaDescription,
  title: _title,
  metaTitle: mTitle,
  metaDescription: mDescription,
  featuredImage,
  facebookUrl,
  instagramUrl,
  tikTokUrl,
  bgColor = '#F9F9F9',
  fluid = false,
  ...rest
}) => {
  useEffect(() => {
    if (bgColor) document.body.style.backgroundColor = bgColor;
  }, [bgColor]);
  const { t } = useI18n();
  const title = i18nTitle ? t(i18nTitle) : _title;
  const metaTitle = i18nMetaTitle ? t(i18nMetaTitle) : mTitle || title;
  const metaDescription = i18nMetaDescription ? t(i18nMetaDescription) : mDescription;

  return (
    <Page {...{ featuredImage, metaDescription, metaTitle, ...rest }}>
      <Grid fluid={fluid} style={bgColor && { backgroundColor: bgColor }} className={s.content}>
        <Row className={s.row}>
          <Col md={12}>
            {[title, facebookUrl, instagramUrl, tikTokUrl].every((item) => !isNil(item)) ? (
              <>
                <div className={s.topSection}>
                  <Title className={s.title}>{title}</Title>
                  <div className={s.socialContainer}>
                    <span className={s.divide}>{t('blog.divide')}</span>
                    <div className={s.wrapper}>
                      <a className={s.social} href={facebookUrl} target="_blank" rel="noreferrer">
                        <img src={facebookIconSrc} alt="facebook" />
                        <span>{t('blog.facebook')}</span>
                      </a>
                      <a className={s.social} href={instagramUrl} target="_blank" rel="noreferrer">
                        <img src={instagramIconSrc} alt="instagram" />
                        <span>{t('blog.instagram')}</span>
                      </a>
                      <a className={s.social} href={tikTokUrl} target="_blank" rel="noreferrer">
                        <img src={tikTokIconSrc} alt="tiktok" />
                        <span>{t('blog.tiktok')}</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className={s.divider} />
              </>
            ) : null}
            {title && !facebookUrl && !instagramUrl ? <Title className={s.title}>{title}</Title> : null}
            {children}
          </Col>
        </Row>
      </Grid>
      <Footer />
    </Page>
  );
};

export default PageLayout;
