/* eslint-disable class-methods-use-this */
import routePaths from 'constants/route-paths';
import EventEmitter from 'events';
import { navigate } from 'gatsby';
import get from 'lodash/get';
import template from 'lodash/template';
import templateSettings from 'lodash/templateSettings';

const DEFAULT_LANGUAGE = 'de';

const flattenObject = (obj, prefix = '') =>
  Object.keys(obj).reduce((acc, key) => {
    const pre = prefix.length ? `${prefix}.` : '';
    if (typeof obj[key] === 'object' && obj[key] !== null && Object.keys(obj[key]).length > 0) {
      Object.assign(acc, flattenObject(obj[key], pre + key));
    } else {
      acc[pre + key] = obj[key];
    }
    return acc;
  }, {});

// inspired by https://github.com/edouardpagnier/react-light-i18n
class I18n {
  eventTypes = {
    LOCALE_CHANGE: 'LOCALE_CHANGE',
  };

  ee = new EventEmitter();

  translations;

  locale;

  languageFallback;

  setTranslations = (translations, fallback = undefined) => {
    this.translations = translations;
    if (this.locale === undefined) {
      const tmpLocale = this.getLocale();
      this.locale = tmpLocale.includes('-') ? tmpLocale.split('-')[0] : tmpLocale;
    }
    this.languageFallback = fallback === undefined ? DEFAULT_LANGUAGE : fallback;
  };

  getTranslations = (locale) => {
    return this.translations[locale || this.locale];
  };

  setLocale(locale) {
    const prevLocale = this.locale;
    this.locale = locale;
    this.ee.emit(this.eventTypes.LOCALE_CHANGE, this.locale);

    if (typeof window !== `undefined`) {
      const { pathname } = window.location;
      const path = pathname.endsWith('/') ? pathname.slice(0, -1) : pathname;
      localStorage.setItem('language', this.locale);

      const routePathKey = Object.keys(flattenObject(routePaths[prevLocale])).find((routePath) => {
        const routePathValue = get(routePaths, `${prevLocale}.${routePath}`);
        return routePathValue === path;
      });

      if (routePathKey) {
        navigate(get(routePaths, `${this.locale}.${routePathKey}`));
      }
    }
  }

  t = (key, values) => {
    if (this.translations !== undefined) {
      if (this.locale in this.translations) {
        templateSettings.interpolate = /{([\s\S]+?)}/g;
        const str = get(this.translations[this.locale], key);
        const completed = template(str);
        return completed(values) || `No ${this.locale} translation for ${key}`;
      }
      if (key in this.translations[this.languageFallback]) {
        return this.translations[this.languageFallback][key];
      }
      return `No ${this.locale} translation for ${key}`;
    }
    // eslint-disable-next-line no-console
    console.warn('ERROR: No translation defined, consider calling setTranslations() first.');
    return '';
  };

  getLocale() {
    let locale;
    if (typeof window !== `undefined`) {
      locale = localStorage.getItem('language');
      if (locale) return locale;
      // if (navigator.languages && navigator.languages.length) {
      //   locale = navigator.languages[0];
      // } else if (navigator.userLanguage) {
      //   locale = navigator.userLanguage;
      // } else {
      //   locale = navigator.language;
      // }
    }
    if (locale) {
      locale = locale.includes('-') ? locale.split('-')[0] : locale;
    }
    const locales = this.getLocales();
    return locales.includes(locale) ? locale : DEFAULT_LANGUAGE;
  }

  getLocales() {
    return Object.keys(this.translations);
  }

  onLocaleChange(cb) {
    this.ee.on(this.eventTypes.LOCALE_CHANGE, cb);
    return () => this.ee.off(this.eventTypes.LOCALE_CHANGE, cb);
  }
}

export default new I18n();
