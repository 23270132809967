import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { useI18n } from 'i18n';
import { Link } from 'gatsby';
// import mainLogoSrc from 'assets/icons/logo.svg';
import { ReactComponent as Logo } from 'assets/icons/logo.svg';
import facebookLogoSrc from 'assets/icons/facebook.svg';
import instagramLogoSrc from 'assets/icons/instagram.svg';
import tikTokLogoSrc from 'assets/icons/tiktok.svg';
import { defaultFacebookUrl, defaultInstagramUrl, defaultTikTokUrl } from 'config/settings';
import routePaths from 'constants/route-paths';
import s from './Footer.module.scss';

const Footer = () => {
  const { t, locale } = useI18n();
  return (
    <footer className={s.footer}>
      <Grid className={s.gridContainer}>
        <Row className={s.row}>
          <Col md={2} className={s.logoContainer}>
            {/* <img src={mainLogoSrc} alt={t('alt_text.footer.main_logo')} /> */}
            <Logo height="30px" width="100px" />
          </Col>
          <Col md={2} lg={3} className={s.copyright}>
            {t('footer.copyright', { year: new Date().getFullYear() })}
          </Col>
          <Col md={3} lg={2}>
            <Link to={routePaths[locale].privacyPolicy}>{t('footer.privacy')}</Link>
          </Col>
          <Col md={3} lg={2}>
            <Link to={routePaths[locale].termsOfUse}>{t('footer.terms_of_use')}</Link>
          </Col>
          <Col md={1} lg={2}>
            <Link to={routePaths[locale].cookies}>{t('footer.cookie')}</Link>
          </Col>
          <Col md={1} className={s.socialIcons}>
            <a className={s.social} href={defaultFacebookUrl} target="_blank" rel="noreferrer">
              <img src={facebookLogoSrc} alt={t('alt_text.footer.facebook_logo')} />
              <span className={s.text}>{t('blog.facebook')}</span>
            </a>
            <a className={s.social} href={defaultInstagramUrl} target="_blank" rel="noreferrer">
              <img src={instagramLogoSrc} alt={t('alt_text.footer.instagram_logo')} />
              <span className={s.text}>{t('blog.instagram')}</span>
            </a>
            <a className={s.social} href={defaultTikTokUrl} target="_blank" rel="noreferrer">
              <img src={tikTokLogoSrc} alt={t('alt_text.footer.tik_tok_logo')} />
              <span className={s.text}>{t('blog.tiktok')}</span>
            </a>
          </Col>
        </Row>
      </Grid>
    </footer>
  );
};

export default Footer;
