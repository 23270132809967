export const trackCTAClick = (id) => {
  console.log(`LANDING_PAGE_CTA_${String(id).toUpperCase()}_CLICK`);
  try {
    window.dataLayer.push({
      event: `LANDING_PAGE_CTA_${String(id).toUpperCase()}_CLICK`,
    });
  } catch (error) {
    console.log(error);
  }
};
