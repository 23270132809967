import React, { useState, useCallback, createContext } from 'react';

const useNavState = () => {
  const [navOpen, setNav] = useState(useNavState.defaults.navOpen);
  const toggleNav = useCallback((v) => setNav(typeof v === 'boolean' ? v : (s) => !s), []);
  return { navOpen, toggleNav };
};

useNavState.defaults = {
  navOpen: false,
  toggleNav: () => {},
};

export const AppContext = createContext({
  ...useNavState.defaults,
});

export const AppContextProvider = ({ children, ...value }) => {
  const navState = useNavState();
  return <AppContext.Provider value={{ ...value, ...navState }}>{children}</AppContext.Provider>;
};

export const AppContextConsumer = AppContext.Consumer;

export const useAppContext = () => React.useContext(AppContext);
