import { useI18n } from 'i18n';
import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import s from './LanguageSwitcher.module.scss';

function calculateIndentLeft({ locales = [], locale }) {
  const initialIndent = 3;
  if (locales.length === 0) return initialIndent;

  const activeLocaleIdx = locales.findIndex((lng) => lng === locale);
  if (activeLocaleIdx === 0) return initialIndent;

  return (100 / locales.length) * activeLocaleIdx - initialIndent;
}

function LanguageSwitcher() {
  const { locale, i18n } = useI18n();
  const locales = i18n.getLocales();
  const [indentLeft, setIndentLeft] = useState(calculateIndentLeft({ locales, locale }));
  const [isClient, setClient] = useState(false);

  useEffect(() => {
    if (!isClient && typeof window !== 'undefined') {
      setClient(true);
    }
  }, [isClient]);

  useEffect(() => {
    const calculatedIndentLeft = calculateIndentLeft({ locales, locale });
    setIndentLeft(calculatedIndentLeft);
  }, [indentLeft, locale, locales]);

  return (
    <div className={s.localesContainer} key={isClient}>
      <div className={s.whiteBackground} style={{ left: `${indentLeft}%` }} />
      {locales.map((l) => {
        return (
          <button
            key={l}
            type="button"
            onClick={() => i18n.setLocale(l)}
            className={cn(s.localeItem, locale === l ? s.activeLocaleItem : '')}
          >
            {l}
          </button>
        );
      })}
    </div>
  );
}

export default LanguageSwitcher;
